@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.heading {
	font-style: normal;
	@include headline5;

	strong {
		font-weight: 400;
	}

	margin-bottom: 2rem;

	@include sm-down {
		@include headline6;

		margin-bottom: 2rem;
	}
}

.posts {
	.post {
		display: grid;
		grid-template-columns: minmax(600px, 1fr) 1fr;
		gap: 30px;

		.img {
			width: 100%;
			display: block;
			height: 100%;
			object-fit: cover;
			max-height: 300px;
		}

		.h3 {
			@include body1;
			margin-bottom: 1.5rem;
		}
	}
}

.postslider {
	@include xs-only {
		display: block;

		> a {
			width: 100%;
			margin-bottom: 30px;

			> img {
				margin-bottom: 10px !important;
			}
		}
	}
}

.excerpts {
	:global(.post_excerpt) {
		margin-bottom: 40px;
	}
}

.fitness_hero {
	@include sm-down {
		img {
			object-position: 30%;
		}
	}
}

.wellness_hero {
	@include md-up {
		img {
			object-position: 85%;
		}
	}
	@include sm-down {
		img {
			object-position: 85%;
		}
	}
}

.science_hero {
	@include sm-down {
		img {
			object-position: 57%;
		}
	}
}
