@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.post {
	.h3 {
		@include headline8;
		margin-bottom: 0.5rem;

		@include xs-only {
			@include headline8;
			max-width: 90%;

			margin-bottom: 2rem;
		}
	}

	.img {
		width: 100%;
		display: block;
		max-width: 100%;
		height: 250px;
		object-fit: cover;
		margin-bottom: 20px;
	}

	:global(.sharedaddy) {
		display: none;
	}

	small {
		margin-top: 10px;
		display: block;
		color: #797c8e;
	}

	.excerpt,
	.date {
		display: none;
		@include sm-up {
			display: block;
		}
	}

	@include xs-only {
		display: block;
		width: calc(100vw - 48px - 60px);
	}

	.tag {
		display: none;
	}

	@include md-up {
		.tag {
			display: flex;
			gap: 10px;
			font-weight: 700;
			font-size: 15px;
			line-height: 140%;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			margin-bottom: 15px;
			align-items: flex-start;

			> span {
				@include eyebrow1;
			}
		}
	}
}

.post_excerpt {
	:global(.sharedaddy) {
		display: none;
	}
	@include sm-up {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		padding-left: $sm_margin;
		padding-right: $sm_margin;
		column-gap: 30px;
		> .thumbnail_c {
			grid-column: 1 / span 4;
		}
		.text {
			grid-column: 5 / span 8;
			padding: 0 !important;
		}
	}
	@include md-up {
		padding-left: $md_margin;
		padding-right: $md_margin;
		> .thumbnail_c {
			grid-column: 1 / span 5;
		}
		.text {
			grid-column: 6 / span 7;
		}
	}

	@include xs-only {
		> .thumbnail_c {
			aspect-ratio: 1;

			> a {
				height: 100%;
				display: flex;

				> img {
					object-fit: cover;
					object-position: top;
				}
			}
		}
	}

	.text {
		padding: 20px;
	}
	.title {
		@include headline7;
		margin-bottom: 1.5rem;

		> a {
			color: #000;
		}
	}
	.date {
		font-weight: 400;
		font-size: 12px;
		line-height: 140%;
		color: #797c8e;
	}
	.html {
		p {
			margin-bottom: 10 px;
		}
	}
	.thumbnail_c {
		width: 100%;
		overflow: hidden;
		@include sm-up {
			img {
				height: 20vw;
				width: 100%;
				object-fit: cover;
				object-position: top;
			}
		}
	}
}
